<template>
  <v-dialog
    v-model="isModalOpen"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title class="text-h3 font-weight-medium">
        {{ $t("validate_reception") }}
      </v-card-title>
      <v-card-text class="text-center">
        {{ $t("validate_transfer_reception_text") }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2"
          @click="close()"
          :disabled="isLoading"
          color="indigo"
          text
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          class="primary"
          @click="confirm()"
          :loading="isLoading"
          :disabled="isLoading"
          text
        >
          {{ $t("validate") }}</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
    transfer: Object,
  },
  mounted: function () {
    this.currentTransfer = { ...this.transfer };
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    close() {
      this.toggleModal();
    },
    async confirm() {
      this.isLoading = true;
      try {
        this.currentTransfer.supply_order_status = "confirmed";
        await this.$store.dispatch(
          "transferProducts/validateReception",
          this.currentTransfer,
          {
            transfer_id: this.currentTransfer.id,
          }
        );

        this.toggleModal();
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>